import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {games, winrateToDifficulty} from "@/games";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  ...Object.values(winrateToDifficulty).map(d => {
    return {
      path: `/${d.urlpath}`,
      name: `difficulty-${d.urlpath}`,
      component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
      props: {
        difficulty: d
      }
    }
  }),
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUsView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUseView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "about" */ '../views/CookiesView.vue')
  },
  {
    path: '/games/solitaire-collection/privacy-policy',
    name: 'solitaire-collection-privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/GamePrivacyPolicyView.vue')
  },
  {
    path: '/admob-providers',
    name: 'admob-providers',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdmobProviders.vue')
  },
  ...games.map(g => {
    return {
      path: `/${g.urlpath}`,
      name: `game-${g.classname}`,
      component: () => import(/* webpackChunkName: "about" */ '../views/GameView.vue'),
      props: g
    }
  })
]

const router = createRouter({
  history: process.env.NODE_ENV === "development" ?
    createWebHistory(process.env.BASE_URL) :
    createWebHistory(process.env.BASE_URL),
  routes
})

export default router
