<template>
  <v-footer class="footer-toolbar">
    <v-col style="text-align: left; font-size: 12px;" :cols="$vuetify.display.mdAndUp ? 4 : 6">
      © 2024 <strong>Classic Card Games</strong>.<br>
      All Rights Reserved<br>
      Classic Card Games Limited. Registered in England and Wales. Company registration number 9421379.<br>
      Registered address 400 Thames Valley Park Drive, Reading, Berkshire, England, RG6 1PT.
    </v-col>

    <v-col :cols="$vuetify.display.mdAndUp ? 8 : 6">
      <v-row no-gutters>
        <v-col class="align-self-start" :cols="$vuetify.display.mdAndUp ? 6 : 12">
          <nav>
            <router-link to="/about">About</router-link>
            <br>
            <router-link to="/contact-us">Contact Us</router-link>
          </nav>
        </v-col>

        <v-col class="align-self-start" :cols="$vuetify.display.mdAndUp ? 6 : 12">
          <nav>
            <router-link to="/terms-of-use">Terms of use</router-link>
            <br>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
            <br>
            <router-link to="/cookies">Cookies</router-link>
          </nav>
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterToolbar',
}
</script>

<style scoped>
.footer-toolbar {
  flex: 0 1 40px;
}

nav a {
  /*noinspection CssUnresolvedCustomProperty*/
  color: rgb(var(--v-theme-ground1));
}

nav a.router-link-exact-active {
  /*noinspection CssUnresolvedCustomProperty*/
  color: lightblue;
}
</style>