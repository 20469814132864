<template>
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline"
    @clicked-postpone="cookieClickedPostpone"
    @removed-cookie="cookieRemovedCookie"
    @status="cookieStatus"
    elementId="myPanel1"
    position="bottom-left"
    ref="myPanel1"
    transitionName="slideFromBottom"
    type="floating"
  >
    <!-- Optional -->
    <template #postponeContent>&times;</template>

    <!-- Optional -->
    <template #message>
      We use cookies to ensure you get the best experience on our website.
      <router-link to="/cookies">
        Learn more...
      </router-link>
    </template>

    <!-- Optional -->
    <template #declineContent>Opt Out</template>

    <!-- Optional -->
    <template #acceptContent>Got It!</template>
  </vue-cookie-accept-decline>
</template>

<script>
export default {
  name: "CookieBanner",
  methods: {
    cookieClickedAccept() {
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    },
    cookieClickedDecline() {
      gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
    },
    cookieClickedPostpone() {
      console.log("cookieClickedPostpone")
    },
    cookieRemovedCookie() {
      gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
    },
    cookieStatus(status) {
      if (status !== "accept") {
        gtag('consent', 'update', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });
      }
    }
  }
};
</script>

<style scoped>
  @import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
</style>