import App from './App.vue'
import router from './router'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import { createApp } from 'vue'
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as colors from 'vuetify/util/colors'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

const app = createApp(App);
app.use(router);

app.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'alt1',
    themes: {
      alt1: {
        dark: false,
        colors: {
          brand1: '#034F4F',
          brand2: '#24A58E',
          pop1: '#EA9238',
          pop2: '#FACE71',
          ground1: '#FFFFFF',
          ground2: '#000000',
        }
      },
      alt2: {
        dark: false,
        colors: {
          brand1: '#132907',
          brand2: '#94AF29',
          pop1: '#880103',
          pop2: '#D40100',
          ground1: '#FFFFFF',
          ground2: '#000000',
        }
      },
    },
  },
});
app.use(vuetify);

app.mount('#app');
