<template>
  <div class="mx-auto" :style="containerStyles">
    <ins class="adsbygoogle adsense-ad"
         data-ad-client="ca-pub-6201560790943326"
         :style="insStyles"
         :data-ad-format="adFormat"
         :data-ad-slot="adSlotId"></ins>
  </div>
</template>

<script>

import {nextTick} from "vue";

export default {
  name: 'GoogleAd',
  data: () => {
    return {
      adFormat: undefined,
      containerStyles: '',
      insStyles: ''
    }
  },
  props: {
    adSlotId: {
      type: String,
      required: true
    },
    height: {
      type: String
    },
    width: {
      type: String
    },
  },
  beforeMount() {
    // Default values of 100% and 90px;
    const widthStyle = 'width:' + (this.width ?? '100%') + ';';
    const heightStyle = 'height:' + (this.height ?? '90px') + ';';

    if (this.width && this.height)
      this.adFormat = "auto";
    else if (this.width)
      this.adFormat = "vertical";
    else if (this.height)
      this.adFormat = "horizontal";

    // The container needs to have a non zero size when rendering, otherwise the ad will not display.
    this.containerStyles = widthStyle + heightStyle;
    this.insStyles ='display:inline-block;' + this.containerStyles;
  },
  created() {
    nextTick(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      // Removing the sizes from the container after the ad has loaded will allow it to center itself with margin-auto.
      this.containerStyles = '';
    })
  }
}
</script>

<style scoped>
ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}
</style>