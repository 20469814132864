<template>
  <div class="home">
    <v-col v-if="$vuetify.display.xlAndUp" :style="{'max-width': '200px'}" class="pa-0">
      <v-row class="home-side-menu">
        <h1 class="ml-3 mt-3">Categories</h1>
        <router-link to="/">
          All Card Games
        </router-link>
        <router-link
          v-for="difficulty in Object.values(winrateToDifficulty)"
          :key="`home-side-menu-${difficulty.name.toLowerCase()}`"
          :to="`/${difficulty.urlpath}`">
          {{ difficulty.name }} Card Games
        </router-link>
      </v-row>
    </v-col>

    <v-col class="home-contents">
      <v-row align="center" justify="center" class="text-center pt-3">
        <h1>Top Solitaire Games</h1>
      </v-row>
      <v-row align="center" justify="center">
        <game-list-column :games="topGames" :item-width="200"/>
      </v-row>
      <v-row class="overflow-hidden">
        <google-ad ad-slot-id="5723648399" height="90px"></google-ad>
      </v-row>

      <v-row align="center" justify="center" class="text-center pt-3" ref="pagination-scroll-to">
        <h1>All Solitaire Games</h1>
      </v-row>
      <v-row align="center" justify="center">
        <game-list-column :games="filteredGameList.slice((page-1)*60, page*60)" :item-width="200"/>
      </v-row>
      <v-row align="center" justify="center" class="text-center pt-3">
        <v-pagination
            v-model="page"
            rounded="circle"
            :length="Math.ceil(filteredGameList.length/60)"
            :total-visible="Math.ceil(filteredGameList.length/60)"/>
      </v-row>
      <v-row class="overflow-hidden">
        <google-ad ad-slot-id="5723648399" width="100%" height="90px"></google-ad>
      </v-row>
    </v-col>

    <v-col v-if="$vuetify.display.xlAndUp" :style="{'max-width': '200px'}" class="pa-0">
      <v-row v-if="!reloading" class="stick-ad" style="right: 60px">
        <google-ad ad-slot-id="5723648399" width="100%" height="100%"></google-ad>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import {winrateToDifficulty, games} from "@/games";
import GoogleAd from "@/components/GoogleAd.vue";
import GameListColumn from "@/components/GameListColumn.vue";
import {nextTick} from "vue";

const topGames = games.filter(g => g["top_game"]);

export default {
  name: 'HomeView',
  components: {GameListColumn, GoogleAd},
  data: () => ({
    winrateToDifficulty,
    topGames,
    reloading: false,
    originalTitle: null,
    originalDescription: null,
    page: 1,
  }),
  props: {
    difficulty: {
      type: Object,
      required: false
    }
  },
  computed: {
    filteredGameList() {
      let otherGames = games.filter(g => !g["top_game"]);
      if (this.difficulty)
        otherGames = otherGames.filter(g => g.difficulty?.name === this.difficulty.name);
      return otherGames;
    }
  },
  methods: {
    setPageContent() {
      if (this.difficulty?.title)
        document.title = this.difficulty.title;
      else if (this.originalTitle)
        document.title = this.originalTitle;

      const s = document.getElementById("page-description");
      if (s && this.difficulty?.description)
        s.content = this.difficulty.description;
      else if (s && this.originalDescription)
        s.content = this.originalDescription;
    },
  },
  beforeMount() {
    this.originalTitle = document.title;

    const s = document.getElementById("page-description");
    if (s)
      this.originalDescription = s.content;

    this.setPageContent();
  },
  beforeUnmount() {
    if (this.originalTitle)
      document.title = this.originalTitle;

    const s = document.getElementById("page-description");
    if (s && this.originalDescription)
      s.content = this.originalDescription;
  },
  watch: {
    page: function() {
      this.reloading = true;
      nextTick(() => {
        this.reloading = false;
        this.$refs["pagination-scroll-to"]?.$el?.scrollIntoView({ behavior: 'smooth' });
      });
    },
    $route: function() {
      this.page = 1;
      this.setPageContent();
    }
  }
}
</script>

<style scoped>
.home {
  position: relative;
  display: flex;
}

.home-side-menu {
  background-color: rgb(var(--v-theme-brand1));
  position: absolute;
  flex-direction: column;
  top: 12px;
  left: 12px;
  height: calc(100% + 12px);
  width: 250px;
  padding: 12px;
  color: rgb(var(--v-theme-ground1));

  a {
    margin-top: 12px;
    margin-left: 24px;
    display: inline-block;
    color: rgb(var(--v-theme-ground1));
  }
}

.home-contents {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.stick-ad {
  position: fixed;
  top: 50%;
  width: 200px;
  transform: translate(0, -50%);
}
</style>
