<template>
  <v-app>
    <header-toolbar class="ccg-header"/>
    <img alt="Classic Card Games" src="./assets/logo.png" height="200" class="ccg-logo">
    <router-view class="ccg-view" :class="$vuetify.display.smAndDown ? 'pl-3 pr-3' : 'pl-12 pr-12'"/>
    <footer-toolbar class="ccg-footer"/>
    <cookie-banner/>
  </v-app>
</template>

<script>
import HeaderToolbar from "@/components/HeaderToolbar.vue";
import FooterToolbar from "@/components/FooterToolbar.vue";
import CookieBanner from "@/views/CookieBanner.vue";

export default {
  components: {
    CookieBanner,
    FooterToolbar,
    HeaderToolbar
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      gtag('event', 'navigate', {
        navigate_from: oldRoute.name ? location.origin + oldRoute.path : null,
        navigate_to: newRoute.name ? location.origin + newRoute.path : null,
      });
    }
  }
}
</script>

<style>
#app {
  font-family: "Inter", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ccg-header {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: rgb(var(--v-theme-brand1)) !important;
  /*noinspection CssUnresolvedCustomProperty*/
  color: rgb(var(--v-theme-ground1)) !important;
}

.ccg-logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 60px;
  z-index: 1;
}

.ccg-view {
  flex: 1 1 auto;
  padding-top: 144px;
  padding-bottom: 48px;
  /*noinspection CssUnresolvedCustomProperty*/
  background-image: linear-gradient(
    rgb(var(--v-theme-brand1)) 0%,
    rgb(var(--v-theme-brand2)) 20px,
    rgb(var(--v-theme-brand2)) calc(100% - 20px),
    rgb(var(--v-theme-brand1)) 100%
  ) !important;
  /*noinspection CssUnresolvedCustomProperty*/
  color: rgb(var(--v-theme-ground2)) !important;
}

.ccg-footer {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: rgb(var(--v-theme-brand1)) !important;
  /*noinspection CssUnresolvedCustomProperty*/
  color: rgb(var(--v-theme-ground1)) !important;
}
</style>