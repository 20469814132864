<template>
  <nav class="header-toolbar">
    <v-row class="pa-5" style="max-height: 60px">
      <router-link
        v-for="game in topGames"
        :key="`header-game-${game.classname}`"
        :to="`/${game.urlpath}`"
        v-if="$vuetify.display.lgAndUp">
        {{ game.name }}
      </router-link>

      <router-link to="/" v-if="$vuetify.display.lgAndUp">
        All Games
      </router-link>

      <v-menu v-if="$vuetify.display.mdAndDown">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-menu" v-bind="props" style="margin: -13px 0 0 -13px;"/>
        </template>

        <v-list>
          <v-list-item
            v-for="game in topGames"
            :key="`header-game-${game.classname}`"
            link :to="`/${game.urlpath}`">
            <v-list-item-title>{{  game.name }}</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/">
            <v-list-item-title>All Games</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer/>

      <v-autocomplete
        density="compact"
        label="Search..."
        style="margin: -10px -10px 0 0; width: 200px;"
        v-model="selectedGame"
        bg-color="white"
        rounded
        item-title="name"
        item-value="urlpath"
        :custom-filter="filterGames"
        :items="games"/>
    </v-row>
  </nav>
</template>

<script>
import router from "@/router";
import {games} from "@/games";

const topGames = games.filter(g => g["top_game"]);

export default {
  name: 'HeaderToolbar',
  data: () => ({
    games,
    topGames,
    selectedGame: null
  }),
  methods: {
    filterGames(itemText, queryText, item) {
      if (queryText.length < 3) return false;

      let itemTextParts = itemText
        .toLowerCase()
        .replace(/[^a-z0-9 ]/g, '')
        .split(" ");
      let queryTextParts = queryText
        .toLowerCase()
        .replace(/[^a-z0-9 ]/g, '')
        .split(" ");

      for (let itemTextPart of itemTextParts) {
        for (let queryTextPart of queryTextParts) {
          if (itemTextPart.includes(queryTextPart)) return true;
        }
      }
      return false;
    }
  },
  watch: {
    selectedGame: function(gameUrlPath) {
      if (gameUrlPath)
        router.push(`/${gameUrlPath}`);
    }
  }
}
</script>

<style scoped>
.header-toolbar {
  flex: 0 1 auto;
  padding: 20px;
}

nav a {
  margin-right: 24px;
  display: inline-block;
  text-align: center;
  /*noinspection CssUnresolvedCustomProperty*/
  color: rgb(var(--v-theme-ground1));
}

nav a.router-link-exact-active {
  /*noinspection CssUnresolvedCustomProperty*/
  color: lightblue;
}
</style>