<template>
  <v-col
      v-for="game in games"
      :key="`game-card-${game.urlpath}`"
      cols="auto"
      :class="$vuetify.display.smAndDown ? 'pa-1' : ''">
    <v-card
      flat
      style="background-color: transparent"
      class="mx-auto pa-0 text-center"
      :width="$vuetify.display.smAndDown ? (0.8 * itemWidth) : itemWidth"
      :height="$vuetify.display.smAndDown ? (0.88 * itemWidth) : (1.1 * itemWidth)"
      link :to="`/${game.urlpath}`">
      <v-card-item class="game-card">
        <img :src="`/games/borders/${game.difficulty.name}.png`" class="game-card-border">
        <div class="game-card-title">{{ game.name }}</div>
        <img :src="`/games/thumbnails/${game.classname}.png`" class="game-card-image">
        <div class="game-card-difficulty">{{ game.difficulty.name }}</div>
      </v-card-item>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'GameListColumn',
  props: {
    games: {
      type: Array,
      required: true
    },
    itemWidth: {
      type: Number,
      default: 200
    }
  }
}
</script>

<style scoped>
.game-card-border {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.game-card-image {
  position: absolute;
  left: 6%;
  top: 23%;
  width: 88%;
  height: 54%;
  z-index: 0;
}

.game-card-difficulty {
  font-size: 15px;
  font-weight: bold;
  line-height: 22.5px;
  color: white;
  text-shadow: 0 0 5px #000; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 5px #000;
  -webkit-text-shadow: 0 0 5px #000;

  position: absolute;
  left: 6%;
  top: calc(14% - 7px);
  width: 88%;
  height: 15px;
  z-index: 1;
}

.game-card-title {
  font-size: 15px;
  line-height: 22.5px;
  white-space: nowrap;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 5px #000; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 5px #000;
  -webkit-text-shadow: 0 0 5px #000;

  position: absolute;
  left: 6%;
  bottom: calc(18% - 13px);
  text-overflow: ellipsis;
  overflow:hidden;
  width: 88%;
  height: 20px;
  z-index: 1;
}
</style>